@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

html,
body {
    height: 100%;
    font-family: "Ubuntu", sans-serif;
    background-color: #F4F7FB;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content {
    flex: 1 0 auto;
}

.navlinks {
    font-size: 1.2rem;
    margin-left: 18px;
}

.custom-scrollbar-js,
.custom-scrollbar-css {
    height: 15rem;
}

.custom-scrollbar-css {
    overflow-y: scroll;
}

.custom-scrollbar-css::-webkit-scrollbar {
    width: 5px;
}

.custom-scrollbar-css::-webkit-scrollbar-track {
    background: #eee;
}

.custom-scrollbar-css::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: #232526;
    background-image: linear-gradient(to top, #232526 0%, #414345 100%);
}